function ownKeys(e, r) {var t = Object.keys(e);if (Object.getOwnPropertySymbols) {var o = Object.getOwnPropertySymbols(e);r && (o = o.filter(function (r) {return Object.getOwnPropertyDescriptor(e, r).enumerable;})), t.push.apply(t, o);}return t;}function _objectSpread(e) {for (var r = 1; r < arguments.length; r++) {var t = null != arguments[r] ? arguments[r] : {};r % 2 ? ownKeys(Object(t), !0).forEach(function (r) {_defineProperty(e, r, t[r]);}) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) {Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r));});}return e;}function _defineProperty(e, r, t) {return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e;}function _toPropertyKey(t) {var i = _toPrimitive(t, "string");return "symbol" == typeof i ? i : i + "";}function _toPrimitive(t, r) {if ("object" != typeof t || !t) return t;var e = t[Symbol.toPrimitive];if (void 0 !== e) {var i = e.call(t, r || "default");if ("object" != typeof i) return i;throw new TypeError("@@toPrimitive must return a primitive value.");}return ("string" === r ? String : Number)(t);}










import { getTemplateProperties } from "./getTemplateProperties";

const requiredTemplateProperties = ['_id', 'name', 'color'];

const formatExtractors = (extractors, templates) =>
extractors.map((extractor) => {
  const targetTemplate = getTemplateProperties(
    templates,
    extractor.targetTemplateId,
    requiredTemplateProperties
  );

  const sourceTemplate = getTemplateProperties(
    templates,
    extractor.sourceTemplateId,
    requiredTemplateProperties
  );

  return _objectSpread(_objectSpread({},
  extractor), {}, {
    rowId: extractor._id,
    sourceTemplate,
    targetTemplate });

});

const formatEntityData = (
entities,
templates) =>

entities.map((entity) => {
  const template = getTemplateProperties(
    templates,
    entity.templateId,
    requiredTemplateProperties
  );

  return _objectSpread(_objectSpread({},
  entity), {}, {
    rowId: entity._id || '',
    template });

});

const formatParagraphData = (
paragraphs,
templates,
settings) =>
{var _settings$languages;
  const defaultLanguage = settings === null || settings === void 0 ? void 0 : (_settings$languages = settings.languages) === null || _settings$languages === void 0 ? void 0 : _settings$languages.find((language) => language.default === true);
  const defaultLanguageKey = defaultLanguage === null || defaultLanguage === void 0 ? void 0 : defaultLanguage.key;

  return paragraphs.map((paragraph) => {
    const template = getTemplateProperties(
      templates,
      paragraph.templateId,
      requiredTemplateProperties
    );

    const updatedParagraph = _objectSpread({}, paragraph);

    const paragraphLanguage = defaultLanguageKey || updatedParagraph.languages[0];
    const subRows = [];
    Object.keys(updatedParagraph.versions).forEach((lang) => {
      if (lang !== paragraphLanguage) {
        subRows.push(_objectSpread(_objectSpread({
          text: paragraph.versions[lang] },
        paragraph), {}, {
          languages: [lang] })
        );
      } else {
        updatedParagraph.text = paragraph.versions[lang];
        updatedParagraph.languages = [paragraphLanguage];
      }
    });

    if (subRows.length > 0) {
      updatedParagraph.subRows = subRows;
    }

    return _objectSpread(_objectSpread({},
    updatedParagraph), {}, {
      rowId: updatedParagraph._id || '',
      template });

  });
};

const formatTemplatesToOptions = (templates) =>
templates.map((template) => {
  const option = {
    label: template.name,
    id: template._id,
    searchLabel: template.name,
    value: template._id,
    properties: template.properties
  };
  return option;
});

export { formatEntityData, formatExtractors, formatParagraphData, formatTemplatesToOptions };