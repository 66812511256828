import { TargetTemplateStep } from "./TargetTemplate";
import { SourceTemplateStep } from "./SourceTemplate";
import { ExtractionConfigurationStep } from "./ExtractionConfiguration";

const AddExtractorSteps =


{
  1: {
    Body: TargetTemplateStep.Body,
    Footer: TargetTemplateStep.Footer,
    title: TargetTemplateStep.title
  },
  2: {
    Body: ExtractionConfigurationStep.Body,
    Footer: ExtractionConfigurationStep.Footer,
    title: ExtractionConfigurationStep.title
  },
  3: {
    Body: SourceTemplateStep.Body,
    Footer: SourceTemplateStep.Footer,
    title: SourceTemplateStep.title
  }
};

export { AddExtractorSteps };