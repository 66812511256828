const _excluded = ["rowId", "groupId", "subRows"],_excluded2 = ["rowId", "groupId"],_excluded3 = ["values"],_excluded4 = ["groupId"],_excluded5 = ["subRows"],_excluded6 = ["groupId"];function ownKeys(e, r) {var t = Object.keys(e);if (Object.getOwnPropertySymbols) {var o = Object.getOwnPropertySymbols(e);r && (o = o.filter(function (r) {return Object.getOwnPropertyDescriptor(e, r).enumerable;})), t.push.apply(t, o);}return t;}function _objectSpread(e) {for (var r = 1; r < arguments.length; r++) {var t = null != arguments[r] ? arguments[r] : {};r % 2 ? ownKeys(Object(t), !0).forEach(function (r) {_defineProperty(e, r, t[r]);}) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) {Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r));});}return e;}function _defineProperty(e, r, t) {return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e;}function _toPropertyKey(t) {var i = _toPrimitive(t, "string");return "symbol" == typeof i ? i : i + "";}function _toPrimitive(t, r) {if ("object" != typeof t || !t) return t;var e = t[Symbol.toPrimitive];if (void 0 !== e) {var i = e.call(t, r || "default");if ("object" != typeof i) return i;throw new TypeError("@@toPrimitive must return a primitive value.");}return ("string" === r ? String : Number)(t);}function _objectWithoutProperties(e, t) {if (null == e) return {};var o,r,i = _objectWithoutPropertiesLoose(e, t);if (Object.getOwnPropertySymbols) {var n = Object.getOwnPropertySymbols(e);for (r = 0; r < n.length; r++) o = n[r], -1 === t.indexOf(o) && {}.propertyIsEnumerable.call(e, o) && (i[o] = e[o]);}return i;}function _objectWithoutPropertiesLoose(r, e) {if (null == r) return {};var t = {};for (var n in r) if ({}.hasOwnProperty.call(r, n)) {if (-1 !== e.indexOf(n)) continue;t[n] = r[n];}return t;}



import { assign, isEqual, orderBy, remove } from 'lodash';

import ThesauriAPI from "../../../api/thesauri";

import { httpRequest } from "../../../../../shared/superagent";
import uniqueID from "../../../../../shared/uniqueID";


const rootItemMatch = (item, searchedItem) =>
item.rowId === searchedItem.rowId ? item : undefined;

const findItem = (
items,
searchedItem) =>

items.
map((item) => {var _item$subRows;
  let match = rootItemMatch(item, searchedItem);
  match = match || ((_item$subRows = item.subRows) !== null && _item$subRows !== void 0 && _item$subRows.length ? findItem(item.subRows, searchedItem) : undefined);
  return match;
}).
filter((v) => v)[0];

const sanitizeThesaurusValues = (rows) =>
(rows || []).map((_ref) => {let { rowId: _rowId, groupId: _groupId, subRows: subItems } = _ref,item = _objectWithoutProperties(_ref, _excluded);
  const values = subItems === null || subItems === void 0 ? void 0 : subItems.map((subItem) => {
    const { rowId, groupId } = subItem,rest = _objectWithoutProperties(subItem, _excluded2);
    return rest;
  });
  return values ? assign(item, { values }) : item;
});

const addSelection =
(selectedRows, selection) => (item) => {
  if (item.rowId in selectedRows) {
    selection.push(item);
  }
};

const sanitizeThesauri = (thesaurus) => {
  const sanitizedThesauri = _objectSpread({}, thesaurus);
  sanitizedThesauri.values = sanitizedThesauri.
  values.filter((value) => value.label).
  filter((value) => !value.values || value.values.length).
  map((value) => {
    const _value = _objectSpread({}, value);
    if (_value.values) {
      _value.values = _value.values.filter((_v) => _v.label);
    }
    return _value;
  });
  return sanitizedThesauri;
};

const importThesaurus = async (
thesaurus,
file) =>
{
  const headers = {
    Accept: 'application/json',
    'X-Requested-With': 'XMLHttpRequest'
  };
  const fields = {
    thesauri: JSON.stringify(thesaurus)
  };

  return await httpRequest('thesauris', fields, headers, file);
};

const editThesaurusLoader =
(headers) =>
async ({ params: { _id } }) =>
(await ThesauriAPI.getThesauri({ _id }, headers))[0];

const emptyThesaurus = () => ({
  label: '',
  subRows: [{ label: '', rowId: uniqueID() }],
  rowId: uniqueID()
});

const thesaurusAsRow = (_ref2) => {let { values } = _ref2,item = _objectWithoutProperties(_ref2, _excluded3);return _objectSpread(_objectSpread({},

  item), {}, {
    rowId: item.id || uniqueID(),
    subRows: values === null || values === void 0 ? void 0 : values.map((val) => _objectSpread(_objectSpread({},
    val), {}, {
      rowId: val.id || uniqueID(),
      groupId: item.id })
    ) });};







const pushItemsIntoValues = (items, prev) => {
  items.forEach((_ref3) => {let { groupId } = _ref3,newItem = _objectWithoutProperties(_ref3, _excluded4);
    if (!groupId) {
      prev.push(newItem);
    } else {var _prevGroup$subRows, _prevGroup$subRows2;
      const prevGroup = prev.find((item) => item.rowId === groupId);
      const rest = ((_prevGroup$subRows = prevGroup.subRows) === null || _prevGroup$subRows === void 0 ? void 0 : _prevGroup$subRows.filter((item) => item.rowId !== newItem.rowId)) || [];
      const prevItem = ((_prevGroup$subRows2 = prevGroup.subRows) === null || _prevGroup$subRows2 === void 0 ? void 0 : _prevGroup$subRows2.find((item) => item.rowId === newItem.rowId)) || {};
      prevGroup.subRows = [...rest, _objectSpread(_objectSpread({}, prevItem), newItem)];
    }
  });
};

const removeItem = (prev, deletedItem) => {
  const removed = remove(prev, (item) => item.rowId === deletedItem.rowId);
  if (!removed.length) {
    prev.
    filter((prevItem) => {var _prevItem$subRows;return (_prevItem$subRows = prevItem.subRows) === null || _prevItem$subRows === void 0 ? void 0 : _prevItem$subRows.length;}).
    forEach((prevItem) => {var _prevItem$subRows2;
      remove(prevItem.subRows, (subItem) => subItem.rowId === deletedItem.rowId);
      if (((_prevItem$subRows2 = prevItem.subRows) === null || _prevItem$subRows2 === void 0 ? void 0 : _prevItem$subRows2.length) === 0) {
        remove(prev, (item) => item.rowId === prevItem.rowId);
      }
    });
  }
};

const sortValues =
(
thesaurusValues,
setThesaurusValues) =>

() => {
  const sortedSubRows = thesaurusValues.map((_ref4) => {let { subRows } = _ref4,item = _objectWithoutProperties(_ref4, _excluded5);return (
      !(subRows !== null && subRows !== void 0 && subRows.length) ?
      item : _objectSpread(_objectSpread({},

      item), {}, {
        subRows: [...orderBy(subRows, 'label')] }));}

  );
  setThesaurusValues([...orderBy(sortedSubRows, 'label')]);
};

const addItemSubmit =
(
thesaurusValues,
setThesaurusValues) =>

(items) => {
  const prevItem = items.length && findItem(thesaurusValues, items[0]);
  if (!prevItem) {
    setThesaurusValues((prev) => {
      pushItemsIntoValues(items, prev);
      return [...prev];
    });
  } else {
    prevItem.label = items[0].label;
    setThesaurusValues([...thesaurusValues]);
  }
};
const addGroupSubmit =
(
thesaurusValues,
setThesaurusValues) =>

(group) => {
  const prevItem = findItem(thesaurusValues, group);
  if (!prevItem) {
    setThesaurusValues((prev) => {var _group$subRows;
      const subRows = (_group$subRows = group.subRows) === null || _group$subRows === void 0 ? void 0 : _group$subRows.map((_ref5) => {let { groupId: _groupId } = _ref5,item = _objectWithoutProperties(_ref5, _excluded6);return item;});
      prev.push(_objectSpread(_objectSpread({}, group), {}, { subRows }));
      return [...prev];
    });
  } else {
    setThesaurusValues((prev) => {
      pushItemsIntoValues(group.subRows || [], prev);
      const prevGroup = findItem(prev, group);
      prevGroup.label = group.label;
      return [...prev];
    });
  }
};

const compareThesaurus = (
thesaurus,
currentStatus) =>
{
  const changedName = (thesaurus === null || thesaurus === void 0 ? void 0 : thesaurus.name) !== (currentStatus === null || currentStatus === void 0 ? void 0 : currentStatus.name);
  const changedValues = !isEqual(thesaurus === null || thesaurus === void 0 ? void 0 : thesaurus.values, currentStatus === null || currentStatus === void 0 ? void 0 : currentStatus.values);
  return changedName || changedValues;
};

export {
  sanitizeThesaurusValues,
  sanitizeThesauri,
  importThesaurus,
  addSelection,
  editThesaurusLoader,
  emptyThesaurus,
  thesaurusAsRow,
  removeItem,
  sortValues,
  addItemSubmit,
  addGroupSubmit,
  compareThesaurus };