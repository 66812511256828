const _excluded = ["subRows"];function _objectWithoutProperties(e, t) {if (null == e) return {};var o,r,i = _objectWithoutPropertiesLoose(e, t);if (Object.getOwnPropertySymbols) {var n = Object.getOwnPropertySymbols(e);for (r = 0; r < n.length; r++) o = n[r], -1 === t.indexOf(o) && {}.propertyIsEnumerable.call(e, o) && (i[o] = e[o]);}return i;}function _objectWithoutPropertiesLoose(r, e) {if (null == r) return {};var t = {};for (var n in r) if ({}.hasOwnProperty.call(r, n)) {if (-1 !== e.indexOf(n)) continue;t[n] = r[n];}return t;}function ownKeys(e, r) {var t = Object.keys(e);if (Object.getOwnPropertySymbols) {var o = Object.getOwnPropertySymbols(e);r && (o = o.filter(function (r) {return Object.getOwnPropertyDescriptor(e, r).enumerable;})), t.push.apply(t, o);}return t;}function _objectSpread(e) {for (var r = 1; r < arguments.length; r++) {var t = null != arguments[r] ? arguments[r] : {};r % 2 ? ownKeys(Object(t), !0).forEach(function (r) {_defineProperty(e, r, t[r]);}) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) {Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r));});}return e;}function _defineProperty(e, r, t) {return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e;}function _toPropertyKey(t) {var i = _toPrimitive(t, "string");return "symbol" == typeof i ? i : i + "";}function _toPrimitive(t, r) {if ("object" != typeof t || !t) return t;var e = t[Symbol.toPrimitive];if (void 0 !== e) {var i = e.call(t, r || "default");if ("object" != typeof i) return i;throw new TypeError("@@toPrimitive must return a primitive value.");}return ("string" === r ? String : Number)(t);} /* eslint-disable max-lines */
import { uniqBy } from 'lodash';


import { t } from "../../../../../I18N";









const generateChildrenRows = (_suggestion) => {
  const suggestion = _objectSpread(_objectSpread({}, _suggestion), {}, { isChild: false });

  const currentValues = [
  ...(Array.isArray(suggestion.currentValue) ? suggestion.currentValue : [])];


  const suggestedValues = [
  ...(Array.isArray(suggestion.suggestedValue) ? suggestion.suggestedValue : [])];


  suggestion.subRows = [];

  const { subRows } = suggestion,suggestionWithoutChildren = _objectWithoutProperties(suggestion, _excluded);
  suggestedValues.forEach((suggestedValue) => {var _suggestion$subRows;
    const valuePresent = currentValues.find((v) => v === suggestedValue);
    if (valuePresent) {
      currentValues.splice(currentValues.indexOf(valuePresent), 1);
    }

    (_suggestion$subRows = suggestion.subRows) === null || _suggestion$subRows === void 0 ? void 0 : _suggestion$subRows.push(_objectSpread(_objectSpread({},
    suggestionWithoutChildren), {}, {
      suggestedValue,
      currentValue: valuePresent || '',
      propertyName: suggestion.propertyName,
      disableRowSelection: true,
      isChild: true,
      entityTitle: '',
      rowId: `${suggestion.rowId}-${suggestedValue}` })
    );
  });

  currentValues.forEach((currentValue) => {var _suggestion$subRows2;
    (_suggestion$subRows2 = suggestion.subRows) === null || _suggestion$subRows2 === void 0 ? void 0 : _suggestion$subRows2.push(_objectSpread(_objectSpread({},
    suggestionWithoutChildren), {}, {
      suggestedValue: '',
      currentValue,
      disableRowSelection: true,
      isChild: true,
      entityTitle: '',
      rowId: `${suggestion.rowId}-${currentValue}` })
    );
  });

  return suggestion;
};

const _replaceSuggestion = (suggestion, currentSuggestions) =>
currentSuggestions.map((currentSuggestion) => {
  if (currentSuggestion._id === suggestion._id) {
    return suggestion;
  }
  return currentSuggestion;
});

const _updateTitleInSuggestion = (
currentSuggestions,
_suggestionToUpdate,
updatedEntity) =>
{
  const suggestionToUpdate = _objectSpread({}, _suggestionToUpdate);
  const newTitle = updatedEntity.title;
  suggestionToUpdate.currentValue = newTitle;
  suggestionToUpdate.entityTitle = newTitle;
  suggestionToUpdate.state.match = suggestionToUpdate.suggestedValue === newTitle;

  return _replaceSuggestion(suggestionToUpdate, currentSuggestions);
};

// eslint-disable-next-line max-statements
const updateSuggestionsByEntity = (
currentSuggestions,
updatedEntity,
property) =>
{var _suggestionToUpdate2, _updatedEntity$metada, _updatedEntity$metada3;
  if (!updatedEntity) {
    return currentSuggestions;
  }

  let suggestionToUpdate = currentSuggestions.find(
    (currentSuggestion) => currentSuggestion.entityId === updatedEntity._id
  );

  const propertyToUpdate = (_suggestionToUpdate2 = suggestionToUpdate) === null || _suggestionToUpdate2 === void 0 ? void 0 : _suggestionToUpdate2.propertyName;

  if (!suggestionToUpdate || !propertyToUpdate) {
    return currentSuggestions;
  }

  if (propertyToUpdate === 'title' && updatedEntity.title) {
    return _updateTitleInSuggestion(currentSuggestions, suggestionToUpdate, updatedEntity);
  }

  if (!updatedEntity.metadata) {
    return currentSuggestions;
  }

  if ((_updatedEntity$metada = updatedEntity.metadata[propertyToUpdate]) !== null && _updatedEntity$metada !== void 0 && _updatedEntity$metada.length) {var _updatedEntity$metada2;
    const newValue =
    (property === null || property === void 0 ? void 0 : property.type) === 'multiselect' || (property === null || property === void 0 ? void 0 : property.type) === 'relationship' ? (_updatedEntity$metada2 =
    updatedEntity.metadata[propertyToUpdate]) === null || _updatedEntity$metada2 === void 0 ? void 0 : _updatedEntity$metada2.map((v) => v.value) :
    updatedEntity.metadata[propertyToUpdate][0].value;


    suggestionToUpdate.currentValue = newValue;
    suggestionToUpdate.state.match = suggestionToUpdate.suggestedValue === newValue;
  }

  if (!((_updatedEntity$metada3 = updatedEntity.metadata[propertyToUpdate]) !== null && _updatedEntity$metada3 !== void 0 && _updatedEntity$metada3.length)) {
    suggestionToUpdate.currentValue = '';
    suggestionToUpdate.state.match = suggestionToUpdate.suggestedValue === '';
  }

  if ((property === null || property === void 0 ? void 0 : property.type) === 'multiselect' || (property === null || property === void 0 ? void 0 : property.type) === 'relationship') {
    suggestionToUpdate = generateChildrenRows(suggestionToUpdate);
  }

  return _replaceSuggestion(suggestionToUpdate, currentSuggestions);
};

const updateMultiValueSuggestions = (
_parentSuggestion,
acceptedSuggestion) =>
{
  let parentSuggestion = _objectSpread({}, _parentSuggestion);

  const shouldAddValue = acceptedSuggestion.suggestedValue !== '';
  const value = shouldAddValue ?
  acceptedSuggestion.suggestedValue || '' :
  acceptedSuggestion.currentValue || '';

  parentSuggestion.currentValue = _parentSuggestion.currentValue || [];

  parentSuggestion.currentValue = shouldAddValue ?
  parentSuggestion.currentValue.concat(value) :
  parentSuggestion.currentValue.filter((v) => v !== value);

  parentSuggestion.state.match = parentSuggestion.currentValue.every((v) =>
  parentSuggestion.suggestedValue.includes(v)
  );

  parentSuggestion = generateChildrenRows(parentSuggestion);
  return parentSuggestion;
};

const updateSuggestions = (
currentSuggestions,
suggestionsToAccept) =>
{
  if (!suggestionsToAccept.length) {
    return currentSuggestions;
  }

  const acceptedSuggestions = suggestionsToAccept.map((acceptedSuggestion) => {var _suggestion$subRows3;
    let suggestion =
    acceptedSuggestion.isChild ? _objectSpread({},
    currentSuggestions.find((s) => s._id === acceptedSuggestion._id)) : _objectSpread({},
    acceptedSuggestion);


    if (acceptedSuggestion.isChild) {
      suggestion = updateMultiValueSuggestions(
        suggestion,
        acceptedSuggestion
      );
    } else {
      suggestion.state.match = true;
      suggestion.currentValue = acceptedSuggestion.suggestedValue;
    }

    if ('subRows' in suggestion && (_suggestion$subRows3 = suggestion.subRows) !== null && _suggestion$subRows3 !== void 0 && _suggestion$subRows3.length) {
      suggestion = generateChildrenRows(suggestion);
    }

    if (
    acceptedSuggestion.propertyName === 'title' &&
    typeof acceptedSuggestion.suggestedValue === 'string')
    {
      suggestion.entityTitle = acceptedSuggestion.suggestedValue;
    }
    return suggestion;
  });

  const merged = [
  ...currentSuggestions.
  concat(acceptedSuggestions).
  reduce(
    (map, suggestion) =>
    map.set(suggestion._id, Object.assign(map.get(suggestion._id) || {}, suggestion)),
    new Map()
  ).
  values()];


  return merged;
};

const propertyIsInAllTemplates = (
templates,
property) =>

templates.every((template) =>
template.properties.
filter(
  (templateProperty) => templateProperty.type === 'markdown' || templateProperty.type === 'text'
).
some((templateProperty) => {
  if (templateProperty.name === property.propertyName) {
    return true;
  }
  return false;
})
);

// eslint-disable-next-line max-statements
const getAvailableSources = (
templates,
selectedTemplatesIdsAndProperties,
extractor) =>
{var _window$__featureFlag;
  const baseOptions = [
  {
    label: t('System', 'PDF', 'PDF', false),
    value: '0'
  }];


  if (!extractor || extractor !== null && extractor !== void 0 && extractor.source.pdf) {
    baseOptions[0].defaultChecked = true;
  }

  if (!((_window$__featureFlag = window.__featureFlags__) !== null && _window$__featureFlag !== void 0 && _window$__featureFlag.ixExtraSources)) {
    return baseOptions;
  }

  const commonProperty = selectedTemplatesIdsAndProperties[0] ?
  selectedTemplatesIdsAndProperties[0].split('-', 2)[1] :
  '';
  const templateIds = selectedTemplatesIdsAndProperties.map((selected) => selected.split('-', 2)[0]);

  const templatesIncluded = templates.filter((template) =>
  templateIds.includes(template._id.toString())
  );
  let markdownProperties =
  [];

  templatesIncluded.every((template) => {var _template$properties;
    const templateMarkdownProperties = (_template$properties = template.properties) === null || _template$properties === void 0 ? void 0 : _template$properties.filter(
      (property) => property.type === 'markdown' || property.type === 'text'
    );

    if (!templateMarkdownProperties.length) {
      markdownProperties = [];
      return false;
    }

    markdownProperties.push(
      ...templateMarkdownProperties.map((templateMarkdownProperty) => ({
        templateId: template._id.toString(),
        propertyName: templateMarkdownProperty.name,
        propertyLabel: templateMarkdownProperty.label
      }))
    );

    return true;
  });

  const options = [
  ...baseOptions,
  {
    label: t('System', 'Title', 'Title', false),
    value: 'title',
    defaultChecked: false
  },
  ...uniqBy(markdownProperties, 'propertyName').
  filter((markdownProperty) => propertyIsInAllTemplates(templatesIncluded, markdownProperty)).
  map((markdownProperty) => ({
    label: t(
      markdownProperty.templateId,
      markdownProperty.propertyLabel,
      markdownProperty.propertyLabel,
      false
    ),
    value: markdownProperty.propertyName,
    defaultChecked: false
  }))];


  options.some((option) => {
    if (!extractor || extractor.source.pdf) {
      // intentional pass by reference
      // eslint-disable-next-line no-param-reassign
      option.defaultChecked = true;
      return true;
    }
    if (option.value === extractor.source.property) {
      // eslint-disable-next-line no-param-reassign
      option.defaultChecked = true;
      return true;
    }
    return false;
  });

  return options.filter((option) => option.value !== commonProperty);
};

const getMetadataFromProperty = (
entity,
propertyName) =>
{
  if (!propertyName) {
    return { value: '' };
  }

  if (entity !== null && entity !== void 0 && entity.metadata && entity.metadata[propertyName]) {
    const metadataEntry = entity.metadata[propertyName];
    if (metadataEntry.length) {
      const [entry] = metadataEntry;
      return entry;
    }
  }

  return { value: '' };
};

export {
  updateSuggestions,
  updateSuggestionsByEntity,
  generateChildrenRows,
  getAvailableSources,
  getMetadataFromProperty };