const _excluded = ["userSelectedSorting"];function ownKeys(e, r) {var t = Object.keys(e);if (Object.getOwnPropertySymbols) {var o = Object.getOwnPropertySymbols(e);r && (o = o.filter(function (r) {return Object.getOwnPropertyDescriptor(e, r).enumerable;})), t.push.apply(t, o);}return t;}function _objectSpread(e) {for (var r = 1; r < arguments.length; r++) {var t = null != arguments[r] ? arguments[r] : {};r % 2 ? ownKeys(Object(t), !0).forEach(function (r) {_defineProperty(e, r, t[r]);}) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) {Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r));});}return e;}function _defineProperty(e, r, t) {return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e;}function _toPropertyKey(t) {var i = _toPrimitive(t, "string");return "symbol" == typeof i ? i : i + "";}function _toPrimitive(t, r) {if ("object" != typeof t || !t) return t;var e = t[Symbol.toPrimitive];if (void 0 !== e) {var i = e.call(t, r || "default");if ("object" != typeof i) return i;throw new TypeError("@@toPrimitive must return a primitive value.");}return ("string" === r ? String : Number)(t);}function _objectWithoutProperties(e, t) {if (null == e) return {};var o,r,i = _objectWithoutPropertiesLoose(e, t);if (Object.getOwnPropertySymbols) {var n = Object.getOwnPropertySymbols(e);for (r = 0; r < n.length; r++) o = n[r], -1 === t.indexOf(o) && {}.propertyIsEnumerable.call(e, o) && (i[o] = e[o]);}return i;}function _objectWithoutPropertiesLoose(r, e) {if (null == r) return {};var t = {};for (var n in r) if ({}.hasOwnProperty.call(r, n)) {if (-1 !== e.indexOf(n)) continue;t[n] = r[n];}return t;}import { actions } from "../../BasicReducer";
import libraryHelpers from "./libraryFilters";
import api from "../../Search/SearchAPI";
import prioritySortingCriteria from "../../utils/prioritySortingCriteria";
import { risonDecodeOrIgnore } from "../../utils";
import { getThesaurusPropertyNames } from "../../../shared/commonTopicClassification";
import { setTableViewColumns } from "../actions/libraryActions";
import { tocGenerationUtils } from "../../ToggledFeatures/tocGeneration";
import { wrapDispatch } from "../../Multireducer";
import { UserRole } from "../../../shared/types/userSchema";
import { getTableColumns } from "./tableColumns";
import setReduxState from "./setReduxState.js";

const decodeQuery = (params) => {
  try {
    return risonDecodeOrIgnore(params.q || '()');
  } catch (error) {
    // if (error instanceof RangeError) {
    //   console.log()
    return {};
    // }
    // error.status = 404;
    // throw error;
  }
};

const processQuery = (params, globalResources, key) => {var _globalResources$key$;
  const defaultSearch = prioritySortingCriteria.get({ templates: globalResources.templates });

  let query = decodeQuery(params);

  query = Object.assign(query, {
    order: query.order || defaultSearch.order,
    sort:
    query.sort && (query.sort !== '_score' || query.searchTerm) ? query.sort : defaultSearch.sort,
    view: params.view
  });

  const noDocuments = !globalResources[key] || !((_globalResources$key$ = globalResources[key].documents) !== null && _globalResources$key$ !== void 0 && _globalResources$key$.get('rows').size);

  if (noDocuments && query.limit) {
    query = Object.assign(query, { limit: query.limit + (query.from || 0), from: 0 });
  }

  const { userSelectedSorting } = query,sanitizedQuery = _objectWithoutProperties(query, _excluded);

  const loggedIn = globalResources.user && globalResources.user.has('role');
  const isAdmin = loggedIn && globalResources.user.get('role') === UserRole.ADMIN;

  return _objectSpread(_objectSpread(_objectSpread(_objectSpread({},
  tocGenerationUtils.aggregations(sanitizedQuery, globalResources.settings.collection.toJS())),
  loggedIn ? { aggregatePublishingStatus: true } : {}),
  loggedIn && !isAdmin ? { aggregatePermissionsByLevel: true } : {}),
  isAdmin ? { aggregatePermissionsByUsers: true } : {});

};

const requestState = (
requestParams,
globalResources,
options = { calculateTableColumns: false, geolocation: false }) =>
{
  const docsQuery = processQuery(requestParams.data, globalResources, 'library');

  const documentsRequest = requestParams.set(
    tocGenerationUtils.aggregations(docsQuery, globalResources.settings.collection.toJS())
  );

  const markersRequest = options.geolocation ?
  api.search(
    requestParams.set(_objectSpread(_objectSpread({},
    docsQuery), {}, {
      geolocation: true })
    )
  ) :
  { rows: [] };

  return Promise.all([api.search(documentsRequest), markersRequest]).then(
    ([documents, markers]) => {
      const useDefaultPublishedStatus =
      !documentsRequest.data.includeUnpublished && !documentsRequest.data.unpublished;
      const templates = globalResources.templates.toJS();
      const filterState = libraryHelpers.URLQueryToState(_objectSpread(_objectSpread({},

      documentsRequest.data),
      useDefaultPublishedStatus && { includeUnpublished: false, unpublished: false }),

      templates,
      requestParams.data.quickLabelThesaurus ?
      getThesaurusPropertyNames(requestParams.data.quickLabelThesaurus, templates) :
      []
      );

      const state = {
        library: {
          filters: {
            documentTypes: documentsRequest.data.types || [],
            properties: filterState.properties
          },
          aggregations: documents.aggregations,
          search: filterState.search,
          documents,
          markers
        }
      };

      const addinsteadOfSet = Boolean(docsQuery.from);

      const dispatchedActions = [
      setReduxState(state, 'library', addinsteadOfSet),
      actions.set('library.sidepanel.quickLabelState', {
        thesaurus: requestParams.data.quickLabelThesaurus,
        autoSave: false
      })];

      if (options.calculateTableColumns) {
        const tableViewColumns = getTableColumns(documents, templates, documentsRequest.data.types);
        dispatchedActions.push((dispatch) =>
        wrapDispatch(dispatch, 'library')(setTableViewColumns(tableViewColumns))
        );
      }
      return dispatchedActions;
    }
  );
};

export { decodeQuery, processQuery, requestState };