import * as entitiesAPI from "../../../../../../api/paragraphExtractor/entities";

const dialogConfig = {
  headerText: 'Are you sure?',
  warningText: 'All of the paragraphs will be deleted!',
  cancelButtonText: 'No, Cancel',
  acceptButtonText: 'Delete',
  service: entitiesAPI.remove,
  successText: 'Paragraphs deleted',
  errorText: 'An error occurred'
};

export { dialogConfig };