
import api from "../../../utils/api";
import { RequestParams } from "../../../utils/RequestParams";












const lookup = async (
searchTerm,
templates,
headers) =>
{
  try {
    const requestParams = new RequestParams({ searchTerm, templates }, headers);
    if (headers && headers['Content-Language']) {
      api.locale(headers['Content-Language']);
    }
    const response = await api.get('search/lookup', requestParams);
    return response.json;
  } catch (e) {
    return e;
  }
};

export { lookup };