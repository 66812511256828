function ownKeys(e, r) {var t = Object.keys(e);if (Object.getOwnPropertySymbols) {var o = Object.getOwnPropertySymbols(e);r && (o = o.filter(function (r) {return Object.getOwnPropertyDescriptor(e, r).enumerable;})), t.push.apply(t, o);}return t;}function _objectSpread(e) {for (var r = 1; r < arguments.length; r++) {var t = null != arguments[r] ? arguments[r] : {};r % 2 ? ownKeys(Object(t), !0).forEach(function (r) {_defineProperty(e, r, t[r]);}) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) {Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r));});}return e;}function _defineProperty(e, r, t) {return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e;}function _toPropertyKey(t) {var i = _toPrimitive(t, "string");return "symbol" == typeof i ? i : i + "";}function _toPrimitive(t, r) {if ("object" != typeof t || !t) return t;var e = t[Symbol.toPrimitive];if (void 0 !== e) {var i = e.call(t, r || "default");if ("object" != typeof i) return i;throw new TypeError("@@toPrimitive must return a primitive value.");}return ("string" === r ? String : Number)(t);}

import * as extractorsAPI from "../../../api/paragraphExtractor/extractors";
import * as pxParagraphApi from "../../../api/paragraphExtractor/paragraphs";
import * as pxEntitiesApi from "../../../api/paragraphExtractor/entities";
import * as settingsAPI from "../../../api/settings";
import { RequestParams } from "../../../../utils/RequestParams";
import { I18NApi } from "../../../../I18N";

const defaultEntityQuery = {
  filter: {
    extractorId: '',
    status: [],
    languages: []
  },
  page: {
    number: 1,
    size: 10
  },
  sort: {
    property: '',
    order: 'desc'
  }
};

const ParagraphExtractorLoader =
(headers) =>
async () => {
  const extractors = await extractorsAPI.get(headers);
  return { extractors };
};

const buildEntityQuery = (searchParams, extractorId) => _objectSpread(_objectSpread({},
defaultEntityQuery), {}, {
  filter: _objectSpread(_objectSpread({}, defaultEntityQuery.filter), {}, { extractorId }),
  page: {
    number: Number(searchParams.get('page')) || defaultEntityQuery.page.number,
    size: defaultEntityQuery.page.size
  },
  sort: {
    property: searchParams.get('sort') || defaultEntityQuery.sort.property,
    order: searchParams.get('order') || defaultEntityQuery.sort.order
  } });


const PXEntityLoader =
(headers) =>
async ({ params: { extractorId = '' }, request }) => {
  const searchParams = new URLSearchParams(request.url.split('?')[1]);
  const query = buildEntityQuery(searchParams, extractorId);
  const [entities, languages, filters] = await Promise.all([
  pxEntitiesApi.get(query, headers),
  I18NApi.getLanguages(new RequestParams({}, headers)),
  pxEntitiesApi.getFilters()]
  );
  return { entities, languages, filters };
};

const PXParagraphLoader =
(headers) =>
async ({ params: { extractorId = '' } }) => {
  const [paragraphs = [], languages, settings] = await Promise.all([
  pxParagraphApi.getByParagraphExtractorId(extractorId),
  I18NApi.getLanguages(new RequestParams({}, headers)),
  settingsAPI.get(headers)]
  );

  return { paragraphs, extractorId, languages, settings };
};

export { ParagraphExtractorLoader, PXEntityLoader, PXParagraphLoader };