const _excluded = ["from", "to"],_excluded2 = ["dateRange"];function _objectWithoutProperties(e, t) {if (null == e) return {};var o,r,i = _objectWithoutPropertiesLoose(e, t);if (Object.getOwnPropertySymbols) {var n = Object.getOwnPropertySymbols(e);for (r = 0; r < n.length; r++) o = n[r], -1 === t.indexOf(o) && {}.propertyIsEnumerable.call(e, o) && (i[o] = e[o]);}return i;}function _objectWithoutPropertiesLoose(r, e) {if (null == r) return {};var t = {};for (var n in r) if ({}.hasOwnProperty.call(r, n)) {if (-1 !== e.indexOf(n)) continue;t[n] = r[n];}return t;}function ownKeys(e, r) {var t = Object.keys(e);if (Object.getOwnPropertySymbols) {var o = Object.getOwnPropertySymbols(e);r && (o = o.filter(function (r) {return Object.getOwnPropertyDescriptor(e, r).enumerable;})), t.push.apply(t, o);}return t;}function _objectSpread(e) {for (var r = 1; r < arguments.length; r++) {var t = null != arguments[r] ? arguments[r] : {};r % 2 ? ownKeys(Object(t), !0).forEach(function (r) {_defineProperty(e, r, t[r]);}) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) {Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r));});}return e;}function _defineProperty(e, r, t) {return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e;}function _toPropertyKey(t) {var i = _toPrimitive(t, "string");return "symbol" == typeof i ? i : i + "";}function _toPrimitive(t, r) {if ("object" != typeof t || !t) return t;var e = t[Symbol.toPrimitive];if (void 0 !== e) {var i = e.call(t, r || "default");if ("object" != typeof i) return i;throw new TypeError("@@toPrimitive must return a primitive value.");}return ("string" === r ? String : Number)(t);} /* eslint-disable react/jsx-props-no-spreading */
import { createSearchParams } from 'react-router';

import _, { isArray, isEqual, isObject } from 'lodash';
import moment from 'moment';
import { searchParamsFromSearchParams } from "../../../../utils/routeHelpers";

import * as activityLogAPI from "../../../api/activityLog";



const ITEMS_PER_PAGE = 100;











const sortingParams = ['method', 'time', 'username', 'url'];













const timeFilter = (from, to, dateFormat = 'YYYY-MM-DD') => {
  const fromDate = from && moment(from, dateFormat).toDate().getTime();
  const toDate = to && moment(to, dateFormat).toDate().getTime();
  return _objectSpread(_objectSpread({}, fromDate && { from: fromDate }), toDate && { to: toDate });
};

const sortParam = (sort = '', order = '') =>
sortingParams.includes(sort) ? { prop: sort, asc: +(order === 'asc') } : { prop: 'time', asc: 0 };

const paramOrEmpty = (condition, param) => condition ? param : {};

const getQueryParamsBySearchParams = (
searchParams,
dateFormat = 'YYYY-MM-DD') =>
{
  const {
    username,
    search,
    method = [],
    from,
    to,
    sort = 'time',
    order = 'desc',
    page = 1,
    limit = ITEMS_PER_PAGE
  } = searchParams;
  const time = timeFilter(from, to, dateFormat.toUpperCase());
  const sortOptions = sortParam(sort, order);
  const methodList = isArray(method) ? method : [method];
  const params = _objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread({},
  paramOrEmpty(username !== undefined, { username })),
  paramOrEmpty(search !== undefined, { search })),
  paramOrEmpty(time.from !== undefined || time.to !== undefined, { time })),
  paramOrEmpty(methodList[0] !== undefined, { method: methodList })), {}, {
    page,
    limit,
    sort: sortOptions });

  return params;
};

const getAppliedFilters = (searchParams) => {
  let appliedFilters = searchParamsFromSearchParams(searchParams);
  appliedFilters =
  appliedFilters.method && !isArray(appliedFilters.method) ? _objectSpread(_objectSpread({},
  appliedFilters), {}, { method: [appliedFilters.method] }) :
  appliedFilters;
  const { from, to } = appliedFilters,rest = _objectWithoutProperties(appliedFilters, _excluded);
  return _objectSpread(_objectSpread({}, rest), (from || to) && { dateRange: { from, to } });
};

const activityLogLoader =
(headers, handlerContext) =>
async ({ request }) => {
  const { settings } = handlerContext || { dateFormat: 'YYYY-MM-DD' };
  const urlSearchParams = new URLSearchParams(request.url.split('?')[1]);
  const searchParams = searchParamsFromSearchParams(urlSearchParams);
  const params = getQueryParamsBySearchParams(searchParams, settings === null || settings === void 0 ? void 0 : settings.dateFormat);
  const activityLogList = await activityLogAPI.get(params, headers);
  if (activityLogList.message !== undefined) {
    return {
      error: activityLogList.message,
      activityLogData: [],
      totalPages: 0,
      page: 0,
      total: 0
    };
  }
  const totalPages = Math.ceil(activityLogList.totalRows / params.limit);

  return {
    activityLogData: activityLogList.rows.map((row) => _objectSpread(_objectSpread({}, row), {}, { rowId: row._id })),
    totalPages,
    page: params.page,
    total: activityLogList.totalRows
  };
};













const changedParams = (filterPairs) => {
  const newFilters = createSearchParams(filterPairs);
  return Array.from(newFilters).filter(([_key, value]) => value !== '');
};

const setSearchValue = (prev, key, value) => {
  if (!isArray(value)) {
    prev.set(key, value);
  } else if (value.length > 0) {
    prev.set(key, value[0]);
    value.splice(0, 1);
    value.forEach((item) => {
      prev.append(key, item);
    });
  }
};

const filterPairs = (filters) => {
  _.flatMap(filters);
  const pairs = _(filters).toPairs().sortBy(0).value();
  const plainFilters = [];
  pairs.forEach(([key, value]) => {
    if (isArray(value) || !isObject(value)) {
      plainFilters.push([key, value]);
    } else {
      plainFilters.push(..._(value).toPairs().value());
    }
  });
  return _(plainFilters).sortBy(0).value();
};

const updateSearch = (
filters,
searchParams,
setSearchParams) =>
{
  const plainFilters = filterPairs(filters);
  const changedPairs = changedParams(plainFilters);
  if (!isEqual(changedPairs, Array.from(searchParams))) {
    setSearchParams((prev) => {
      prev.delete('page');
      plainFilters.forEach(([key, value]) => {
        if (
        value !== undefined && (
        isArray(value) && value.length > 0 || !isArray(value) && value !== ''))
        {
          setSearchValue(prev, key, value);
        } else {
          prev.delete(key);
        }
      });
      return prev;
    });
  }
};

const buildPageURL = (appliedFilters, pageTo, location) => {
  const _appliedFilters$page$ = _objectSpread(_objectSpread(_objectSpread({},
    appliedFilters), {}, {
      page: pageTo,
      limit: appliedFilters.limit || ITEMS_PER_PAGE },
    appliedFilters.dateRange), {}, {
      dateRange: {} }),{ dateRange } = _appliedFilters$page$,updatedParams = _objectWithoutProperties(_appliedFilters$page$, _excluded2);

  const newParams = [];
  Object.entries(updatedParams).forEach(([key, value]) => {
    if (!isArray(value)) {
      newParams.push([key, value]);
    } else {
      value.forEach((item) => newParams.push([key, item]));
    }
  });
  return `${location.pathname}?${createSearchParams(newParams)}`;
};


export {
  activityLogLoader,
  getAppliedFilters,
  ITEMS_PER_PAGE,
  updateSearch,
  filterPairs,
  getQueryParamsBySearchParams,
  buildPageURL };