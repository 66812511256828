function ownKeys(e, r) {var t = Object.keys(e);if (Object.getOwnPropertySymbols) {var o = Object.getOwnPropertySymbols(e);r && (o = o.filter(function (r) {return Object.getOwnPropertyDescriptor(e, r).enumerable;})), t.push.apply(t, o);}return t;}function _objectSpread(e) {for (var r = 1; r < arguments.length; r++) {var t = null != arguments[r] ? arguments[r] : {};r % 2 ? ownKeys(Object(t), !0).forEach(function (r) {_defineProperty(e, r, t[r]);}) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) {Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r));});}return e;}function _defineProperty(e, r, t) {return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e;}function _toPropertyKey(t) {var i = _toPrimitive(t, "string");return "symbol" == typeof i ? i : i + "";}function _toPrimitive(t, r) {if ("object" != typeof t || !t) return t;var e = t[Symbol.toPrimitive];if (void 0 !== e) {var i = e.call(t, r || "default");if ("object" != typeof i) return i;throw new TypeError("@@toPrimitive must return a primitive value.");}return ("string" === r ? String : Number)(t);}import { redirect } from 'react-router';
import { isClient } from "./";
import { notify } from "../Notifications/actions/notificationsActions";
import { store } from "../store";
import loadingBar from "../App/LoadingProgressBar";
import { t } from "../I18N";

import { APIURL } from "../config.js";
import request from "../../shared/JSONRequest";

let API_URL = APIURL;
let language;

const doneLoading = (data) => {
  loadingBar.done();
  return data;
};

const isNonUsualApiError = (error) => error.status && ![401, 404, 409, 500].includes(error.status);

const errorMessages = [
{
  key: /PayloadTooLargeError: request entity too large/g,
  message: 'Request data too large. Please review text length in property values'
},
{
  key: /ERROR Failed to index documents: (.+)/g,
  message: 'Failed to index documents: {0} '
}];


function extractMessageFromError(error) {
  let finalMessage = `An error has occurred${error.json.requestId ? `, it has been logged with request id #${error.json.requestId}.` : '.'}`;
  if (!error.json.error) return finalMessage;

  const errorMessage = errorMessages.find((errorExpression) =>
  error.json.error.match(errorExpression.key)
  );
  if (errorMessage) {
    const matches = errorMessage.key.exec(error.json.error);
    finalMessage = errorMessage.message;
    for (let i = 0; i < matches.length - 1; i += 1) {
      finalMessage = finalMessage.replace(`{${i}}`, matches[1]);
    }
  }
  return finalMessage;
}

function extractMessageFromValidation(error) {
  if (!error.json.validations) return error.json.error;
  return error.json.validations.reduce((message, validationError, index) => {
    const separator = index < error.json.validations.length - 1 ? ',' : '';
    return `${message} ${validationError.instancePath} ${validationError.message}${separator}`;
  }, `${error.json.error}: `);
}

// eslint-disable-next-line max-statements
const handleErrorStatus = (error) => {
  let errorMessage;
  switch (error.status || true) {
    case 400:
    case 422:{
        errorMessage = extractMessageFromValidation(error);
        store.dispatch(
          notify(t('System', extractMessageFromValidation(error), null, false), 'danger')
        );
        break;
      }

    case 401:
      redirect('/login');
      break;

    case 404:
      redirect('/404');
      break;

    case 409:{
        errorMessage = error.json.error;
        store.dispatch(notify(t('System', error.json.error, null, false), 'warning'));
        break;
      }

    case 500:{
        errorMessage = extractMessageFromError(error);
        store.dispatch(notify(t('System', extractMessageFromError(error), null, false), 'danger'));
        break;
      }

    case isNonUsualApiError(error):{
        errorMessage = error.json.prettyMessage || error.json.error;
        store.dispatch(
          notify(t('System', error.json.prettyMessage || error.json.error, null, false), 'danger')
        );
        break;
      }

    case error instanceof TypeError:{
        errorMessage = 'Could not reach server. Please try again later.';
        store.dispatch(
          notify(
            t('System', 'Could not reach server. Please try again later.', null, false),
            'danger'
          )
        );
        break;
      }

    default:{
        errorMessage = 'An error occurred';
        store.dispatch(notify(t('System', 'An error occurred', null, false), 'danger'));
      }
  }

  return errorMessage;
};

const handleError = (e, endpoint) => {
  const error = e;
  error.endpoint = endpoint;

  if (!isClient) {
    return Promise.reject(error);
  }

  doneLoading();

  handleErrorStatus(error);
  return Promise.reject(error);
};

const _request = (url, req, method) => {
  const headers = _objectSpread(_objectSpread({
    'Content-Language': language },
  req.headers), {}, {
    'X-Requested-With': 'XMLHttpRequest' });


  loadingBar.start();

  return request[method](API_URL + url, req.data, headers).
  then(doneLoading).
  catch(async (e) => {
    await handleError(e, { url, method });
  });
};

export default {
  get: (url, req = {}) => _request(url, req, 'get'),

  post: (url, req = {}) => _request(url, req, 'post'),

  put: (url, req = {}) => _request(url, req, 'put'),

  delete: (url, req = {}) => _request(url, req, 'delete'),

  cookie(c) {
    request.cookie(c);
  },

  locale(locale) {
    language = locale;
  },

  APIURL(url) {
    API_URL = url;
  }
};